<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .ueber-uns-all {
    padding-top:30px;
    @media(max-width:$width-lg) {
      padding-top:0;
    }
    .header-intro-generel-box {
      width: 100%;
      margin-bottom: 0 !important;
      .inner-image-box {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 700px;
        background-position: center center;
        position: relative;

        //border:1px solid red;
        @media (min-width: 2000px) {
          height: 800px;
        }
        @media (min-width: 3000px) {
          height: 900px;
        }
        @media (max-width: 1600px) {
          height: 600px;
        }
        @media (max-width: 1200px) {
          height: 500px;
        }
        @media (max-width: $width-lg) {
          height: 400px;
        }
        @media (max-width: $width-md) {
          height: 350px;
        }

        .title-box {
          position: relative;
          top: 50%;
          transform: translateY(-45%);

          @media (max-width: $width-md) {
            display: block;
          }
          h1 {
            text-align: center;

            span {
              font-family: 'Helvetica Neue LT W05 96 Blk It';
              display: block;
              color: #fff;
              -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
              -webkit-text-stroke-width: 1px;
              -webkit-text-stroke-color: #fff;
              text-transform: uppercase;
              font-style: italic;
              font-weight: 700;
              font-size: 230%;
              @media (max-width: $width-lg) {
                font-size: 150%;
              }
              @media (max-width: $width-md) {
                font-size: 120%;
              }
              @media (max-width: $width-md) {
                font-size: 100%;
              }
            }
          }
        }
      }
    }
    .header-intro-generel-box-v2 {
      margin-top: 0;
      z-index: -2;
      position: relative;
      top: -10px;
      .inner-image-box {
        background-position: center center;
      }
    }
    .header-slideshow-outer {
      position:relative;
      width:100%;
      height:700px;
      z-index:-2;
      @media(min-width: 2000px) {
        height: 800px;
      }
      @media (min-width: 3000px) {
        height: 900px;
      }
      @media (max-width: 1600px) {
        height: 600px;
      }
      @media (max-width: 1200px) {
        height: 500px;
      }
      @media (max-width: $width-lg) {
        height: 400px;
      }
      @media (max-width: $width-md) {
        height: 350px;
      }
    }
    .header-image-slideshow {
      background: no-repeat 50% 50%;
      background-size: cover;
      background-image:none;
     
      //$items: 2;
      // $animation-time: 10s;
      //$transition-time: 10.5s;
      //$scale: 20%;

      //$total-time: ($animation-time * $items);
      //$scale-base-1: (1 + $scale / 100%);
      position: absolute;
      width: 100%;
      height: 700px;
      overflow: hidden;
      @media(min-width: 2000px) {
        height: 800px;
      }
      @media (min-width: 3000px) {
        height: 900px;
      }
      @media (max-width: 1600px) {
        height: 600px;
      }
      @media (max-width: 1200px) {
        height: 500px;
      }
      @media (max-width: $width-lg) {
        height: 400px;
      }
      @media (max-width: $width-md) {
        height: 350px;
      }
      .slideshow-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background: no-repeat 50% 50%;
        background-size: cover;
        animation-name: kenburns;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-duration: 16s;
        opacity: 1;
        transform: scale(1.2);
      }
      
      .slideshow-image:nth-child(1) {
        animation-name: kenburns-1;
        z-index: 3;
      }
      
      .slideshow-image:nth-child(2) {
        animation-name: kenburns-2;
        z-index: 2;
      }
      
      .slideshow-image:nth-child(3) {
        animation-name: kenburns-3;
        z-index: 1;
      }
      
      .slideshow-image:nth-child(4) {
        animation-name: kenburns-4;
        z-index: 0;
      }
      @keyframes 
        kenburns-1 {  0% {
        opacity: 1;
        transform: scale(1.2);
        }
        1.5625% {
        opacity: 1;
        }
        23.4375% {
        opacity: 1;
        }
        26.5625% {
        opacity: 0;
        transform: scale(1);
        }
        100% {
        opacity: 0;
        transform: scale(1.2);
        }
        98.4375% {
        opacity: 0;
        transform: scale(1.21176);
        }
        100% {
        opacity: 1;
        }
      }
      @-webkit-keyframes 
        kenburns-2 {  23.4375% {
        opacity: 1;
        transform: scale(1.2);
        }
        26.5625% {
        opacity: 1;
        }
        48.4375% {
        opacity: 1;
        }
        51.5625% {
        opacity: 0;
        transform: scale(1);
        }
        100% {
        opacity: 0;
        transform: scale(1.2);
        }
      }
      @-webkit-keyframes 
        kenburns-3 {  48.4375% {
        opacity: 1;
        transform: scale(1.2);
        }
        51.5625% {
        opacity: 1;
        }
        73.4375% {
        opacity: 1;
        }
        76.5625% {
        opacity: 0;
        transform: scale(1);
        }
        100% {
        opacity: 0;
        transform: scale(1.2);
        }
      }
      @-webkit-keyframes 
        kenburns-4 {  73.4375% {
        opacity: 1;
        transform: scale(1.2);
        }
        76.5625% {
        opacity: 1;
        }
        98.4375% {
        opacity: 1;
        }
        100% {
        opacity: 0;
        transform: scale(1);
        }
      }
  
        
        
    }
  }
 
  .border-outer-box {
    border:1px solid blue;
    padding: 80px 100px;
    position: relative;
    top: -180px;
    border: 10px solid $blue-60per;
    
    max-width: 80%;
    background: #fff;
    margin: 0 auto;
    
    @media (max-width: $width-lg) {
      max-width: 100%;
      top: -200px;
    }
    @media (max-width: $width-md) {
      max-width: 100%;
      top: -100px;
      padding: 40px 40px;
    }
    &:after {
      content: '';
      background: #fff;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 100px;
      top: 80px;
      border: 10px solid $blue-60per;
      z-index: -1;
      @media (max-width: $width-lg) {
        display: none;
      }
    }
    h2 {
      margin-top: 100px;
      font-family: 'American Typewriter ITC W04 Md';
      margin-bottom: 50px;
      font-size:230%;
      text-transform: none;
      @media(max-width:992px) {
        font-size:180%;
      }
    }
    p {
      //font-family: 'Helvetica Neue LT W05 35 Thin';
      margin-bottom: 30px;
    }
    .img-box {
      text-align: right;
      margin-top: 80px;
      margin-bottom: 50px;
      @media (max-width: $width-md) {
        text-align: center;
      }
      img {
        display: inline-block;
        width: 180px;
        animation: slogan-rotating 15s linear infinite;
  
      }
      @keyframes slogan-rotating {
        from {
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -ms-transform: rotate(-360deg);
          -moz-transform: rotate(-360deg);
          -webkit-transform: rotate(-360deg);
          -o-transform: rotate(-360deg);
          transform: rotate(-360deg);
        }
      }
    }
    
  }

}
</style>

<template>
  <SocialMediaIconBox />
  <div class="ueber-uns-all">
    <div class="header-slideshow-outer">
      <div class="header-image-slideshow">
        <div class="slideshow-image" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/header-buchdruckerei-styria.jpg')})` }"></div>
        <div class="slideshow-image" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/header-styria-hq.jpg')})` }"></div>
        <div class="slideshow-image" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/header-buchdruckerei-styria.jpg')})` }"></div>
        <div class="slideshow-image" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/header-styria-hq.jpg')})` }"></div>
      </div>
    </div>
    <div class="container">
      <div class="border-outer-box">
        <div class="border-outer-box-v2"></div>
        <div class="headline-box-std">
          <div class="headline-inner">
            <div class="breadcrumb"><span>{{$t('General.ueber-uns')}}</span></div>
            <h1 v-html="$t('was-uns-ausmacht.title')"> </h1>
          </div>
        </div>
        <h2>{{$t('was-uns-ausmacht.title-v2')}}</h2>
        <p v-html="$t('was-uns-ausmacht.text-v1')"></p>
        <p v-html="$t('was-uns-ausmacht.text-v2')"></p>
        <p v-html="$t('was-uns-ausmacht.text-v3')"></p>
        <div class="img-box">
          <img src="@/assets/img/ueber-uns/slogan-one-spirit-red.svg" alt="One spirit - unlimited ideas" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Was die STYRIA ausmacht | Styria Media Group ',
      keywords: ['über uns','styria','styria media group','portfolio','biografie','medienkonzern','österreich','kroatien','slowenien','marktumsatz'],
      description: 'Seit ihrer Gründung 1869 ist die STYRIA stetig gewachsen. Wir stehen niemals still und sind heute einer der führenden Medienkonzerne in Österreich, Kroatien und Slowenien.',
      image: require('@/assets/img/og/ueber-uns.png'),
      
    },
    en: {
      title: 'What defines aus | Styria Media Group',
      keywords: ['about us','styria','styria media group','biography','biografie','media company','austria','croatia','slovenia','market turnover'],
      description: ' STYRIA has grown steadily since it was founded in 1869. We never stand still and are now one of the leading media groups in Austria, Croatia and Slovenia.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
