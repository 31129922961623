
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Was die STYRIA ausmacht | Styria Media Group ',
      keywords: ['über uns','styria','styria media group','portfolio','biografie','medienkonzern','österreich','kroatien','slowenien','marktumsatz'],
      description: 'Seit ihrer Gründung 1869 ist die STYRIA stetig gewachsen. Wir stehen niemals still und sind heute einer der führenden Medienkonzerne in Österreich, Kroatien und Slowenien.',
      image: require('@/assets/img/og/ueber-uns.png'),
      
    },
    en: {
      title: 'What defines aus | Styria Media Group',
      keywords: ['about us','styria','styria media group','biography','biografie','media company','austria','croatia','slovenia','market turnover'],
      description: ' STYRIA has grown steadily since it was founded in 1869. We never stand still and are now one of the leading media groups in Austria, Croatia and Slovenia.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
